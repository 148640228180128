import { render, staticRenderFns } from "./BlogDetail.vue?vue&type=template&id=494d6763&scoped=true"
import script from "./BlogDetail.vue?vue&type=script&lang=js"
export * from "./BlogDetail.vue?vue&type=script&lang=js"
import style0 from "./BlogDetail.vue?vue&type=style&index=0&id=494d6763&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494d6763",
  null
  
)

export default component.exports
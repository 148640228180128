<template>
  <div class="blog-comment-container">
    <MessageArea
      :subTitle="`(${data.total})`"
      title="评论列表"
      :list="data.rows"
      :isListLoading="isLoading"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import MessageArea from "@/components/MessageArea";
import { getComments, postComment } from "@/api/blog";
export default {
  components: {
    MessageArea,
  },
  data() {
    return {
      isLoading: false,
      data: {},
      page: 1,
      limit: 10,
    };
  },
  computed: {
    hasMore() {
      return this.data.rows.length < this.data.total;
    },
  },
  async created() {
    this.$bus.$on("mainScroll", this.handleScroll);
    await this.fetchData();
  },
  beforeDestroy() {
    this.$bus.$off("mainScroll", this.handleScroll);
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.data = await getComments(
        this.$route.params.id,
        this.page,
        this.limit
      );
      this.isLoading = false;
    },
    async fetchMore() {
      if (!this.hasMore) {
        return;
      }
      this.page++;
      this.isLoading = true;
      const resp = await getComments(
        this.$route.params.id,
        this.page,
        this.limit
      );
      this.data.total = resp.total;
      this.data.rows = this.data.rows.concat(resp.rows);
      this.isLoading = false;
    },
    handleScroll(dom) {
      if (this.isLoading || !dom) {
        return;
      }
      const range = 100;
      if (
        Math.abs(dom.scrollTop + dom.clientHeight - dom.scrollHeight) <= range
      ) {
        this.fetchMore();
      }
    },

    async handleSubmit(formData, callback) {
      const res = await postComment({
        blogId: this.$route.params.blogId,
        ...formData,
      });
      this.data.rows.unshift(res);
      this.data.total++;
      callback("评论成功");
    },
  },
};
</script>
 
<style lang = "less" scoped>
</style>
<template>
  <Layout>
    <div class="main-container" v-loading="isLoading" ref="mainContainer">
      <BlogDetail :blog="data" v-if="data" />
      <BlogComment v-if="!isLoading" />
    </div>
    <template #right>
      <div class="right-container" v-loading="isLoading">
        <BlogTOC :toc="data.toc" v-if="data" />
      </div>
    </template>
  </Layout>
</template>

<script>
import BlogDetail from "./components/BlogDetail";
import BlogTOC from "./components/BlogTOC";
import BlogComment from "./components/BlogComment";
import Layout from "@/components/Layout";
import { getBlog } from "@/api/blog";
import mainScroll from "@/mixins/mainScroll.js";
import { titleController } from "@/utils";
export default {
  components: {
    BlogDetail,
    BlogTOC,
    BlogComment,
    Layout,
  },
  mixins: [mainScroll("mainContainer")],
  data() {
    return {
      isLoading: false,
      data: null,
    };
  },
  async created() {
    await this.fetchData();
  },
  updated() {
    const hash = location.hash;
    location.hash = "";
    setTimeout(() => {
      location.hash = hash;
    }, 50);
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const res = await getBlog(this.$route.params.id);
      if (!res) {
        this.$router.push("/404");
        return;
      }
      this.data = res;
      titleController.setRouteTitle(this.data.title);
      this.isLoading = false;
    },
  },
};
</script>
 
<style lang = "less" scoped>
.main-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 20px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.right-container {
  width: 300px;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}
</style>